import React, { useState, useEffect, useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/Layout'
import { Posts } from '../components/Posts'
import { SEO } from '../components/SEO'
import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'
import github from '../assets/nav-github.png'
import looking from '../assets/me.jpg'

export default function WebsiteIndex({ data }) {
  const [followers, setFollowers] = useState(0)
  const latest = data.latest.edges
  const simplifiedLatest = useMemo(() => getSimplifiedPosts(latest), [latest])

  useEffect(() => {
    async function getGithubAPI() {
      const response = await fetch('https://api.github.com/users/bangden07')
      const data = await response.json()

      return data
    }

    getGithubAPI().then((data) => {
      setFollowers(data.followers)
    })
  }, [])

  return (
    <>
      <Helmet title={config.siteTitle} />
      <SEO />

      <article className="hero">
        <header>
          <div className="container">
          <div className="flex-content">
              <div>
                <h1>Hey, I'm Bang Den</h1>
                <p className="subtitle small">
                  I'm a blogger in Surabaya. I love {' '}<Link to="/blog"> article</Link> and sharing
                  what I learn. This website is my digital
                  space — summary of the things I have learned and created
                  the years.
                </p>
              </div>
              <img src={looking} alt="Me" className="main-image desktop-only" />
            </div>
            <p className="hero-buttons">
              <Link to="/me" className="button">
                About me
              </Link>
              {followers && (
                <a
                  href="https://github.com/bangden07"
                  className="button icon-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={github} alt="GitHub" />
                  {Number(followers).toLocaleString()} di GitHub
                </a>
              )}
            </p>
          </div>
        </header>

<script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "8ab7e74a6b0a4065bfc4f358a75eaeae"}'></script>

        <div className="container">
          <h2 className="main-header">
            <span>Artikel Terbaru</span> <Link to="/blog">Lihat Semua</Link>
          </h2>
          <Posts data={simplifiedLatest} />
          <h2 className="main-header">Newsletter</h2>
          <div className="flex-content">
            <p>
              Subscribe to the newsletter to get my latest content by email. Unsubscribe anytime.
            </p>
            <p className="hero-buttons">
              <a
                href="https://bangdenspace.substack.com/subscribe"
                className="button"
              >
                Subscribe
              </a>
            </p>
          </div>
        </div>
      </article>
    </>
  )
}

WebsiteIndex.Layout = Layout

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
